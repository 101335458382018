<template>
  <div class="wrap">
    <div class="swiper">
      <!-- 用户信息 -->
      <div class="title_userinfo">
        <!-- <div class="info">
          <div class="left">
            <Avatar :src="userInfo.userimg" size="60" :defaultSrc="require('@tt/assets/defaultAvatar/A6405.png')" />
          </div>
          <div class="right">
            <div class="top">
              <div class="user">
                <div class="nickname">
                  {{ userInfo.username || $t("newAdd.noLoginUserName") }}
                </div>
                <div class="balance">
                  <span class="label">
                    {{ $t("My.MemberInformation.balance") }}:&nbsp;
                  </span>
                  <span class="value">{{ $money(userInfo.money, false) }}</span>
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <img src="@/assets/logo/home.png" class="logo" alt="">
        <div class="right">
          <div class="icon_rule" @click="showRule">
            <i class="iconfont icon-a-Group4051"></i>
          </div>
          <div class="share_content">
            <!-- <img src="../../assets/Img/myImg/share.png" alt="" @click="goshare" /> -->
            <i class="iconfont icon-wodekefu btn_server_icon" @click="toService"></i>
          </div>
        </div>
      </div>
      <!-- 余额 -->
      <!-- <div class="title_balance">
        <div class="user_content">
          <div class="balance_wrap">
            <div class="balance_case">
              <div class="case">
                <p>Blance</p>
                <p style="font-size: 20px">
                  {{ `${verConfig.moneyType}${userInfo.money}` }}
                </p>
              </div>
              <div class="btns">
                <div class="contentCase_btns">
                  <span @click="WithdrawEvent" class="contentCase_btn">
                    {{ $t("My.MemberInformation.Withdraw") }}
                  </span>
                  <span @click="RechargeEvent" class="contentCase_btn">
                    {{ $t("My.MemberInformation.Recharge") }}
                  </span>
                </div>
              </div>
            </div>

            <div class="hr"></div>

            <div class="balance_case">
              <div class="case_bottom">
                <p style="font-size: 12px">{{ $t("add.Commission") }}</p>
                <p>{{ `${verConfig.moneyType}${userInfo.frozen_price}` }}</p>
              </div>
              <div class="case_bottom">
                <p style="font-size: 12px">{{ $t("add.Yesterday") }}</p>
                <p>
                  {{
                      `${verConfig.moneyType}${userInfo.complete_yesterday_price}`
                  }}
                </p>
              </div>
              <div class="case_bottom">
                <p style="font-size: 12px">{{ $t("add.Cumulative") }}</p>
                <p>
                  {{ `${verConfig.moneyType}${userInfo.complete_total_price}` }}
                </p>
              </div>
              <div class="case_bottom">
                <p style="font-size: 12px">{{ $t("add.Today") }}</p>
                <p>
                  {{ `${verConfig.moneyType}${userInfo.frozen_rate_price}` }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <!-- 轮播 -->
    <div class="banner_wrap">
      <Banner />
      <!-- 滚动字幕 -->
      <div class="notice">
        <!-- <div class="icon iconfont">&#xe6cf;</div> -->
        <van-icon name="volume-o" color="#00bbfe" size="22" class="icon" />
        <div class="text">
          <marquee behavior="scroll" direction="left" scrollamount="20" scrolldelay="200" loop="-1">
            <p>
              <span v-for="(item, index) in noticeArr" :key="index">{{
            item
          }}{{ index === noticeArr.length - 1 ? "" : "，" }}</span>
            </p>
          </marquee>
        </div>
      </div>
    </div>
    <div class="btns_wrap">
      <div class="btn_item" @click="toRecharge">
        <!-- <i class="iconfont icon-tixianchongzhi" color=""></i> -->
        <img :src="require('@tt/assets/home/tools/recharge.png')" alt="">
        <div class="text">{{ $t("My.MemberInformation.Recharge") }}</div>
      </div>
      <div class="btn_item" @click="toWithdraw" color="">
        <!-- <i class="iconfont icon-qianbao" color=""></i> -->
        <img :src="require('@tt/assets/home/tools/withdraw.png')" alt="">
        <div class="text">{{ $t("My.MemberInformation.Withdraw") }}</div>
      </div>
      <div class="btn_item" @click="$router.push('/activity')">
        <!-- <i class="iconfont icon-gift-full" color=""></i> -->
        <img :src="require('@tt/assets/home/tools/activity.png')" alt="">
        <div class="text">{{ $t("tab.activity") }}</div>
      </div>
      <div class="btn_item" @click="$router.push('/Message')">
        <!-- <i class="iconfont icon-gift-full" color=""></i> -->
        <Badge :content="unread" :max="99" v-if="unread">
          <img :src="require('@tt/assets/home/tools/message.png')" alt="">
        </Badge>
        <img :src="require('@tt/assets/home/tools/message.png')" alt="" v-else>
        <div class="text">{{ $t("mine.serviceTools.message") }}</div>
      </div>
      <!-- <i class="iconfont icon-licai" color=""></i> -->
      <!-- <div class="btn_item" @click="$router.push('/financial')">
        <img :src="require('@tt/assets/home/tools/financial.png')" alt="">
        <div class="text">{{ $t("Financing.Fina") }}</div>
      </div> -->
    </div>
    <!-- 商家 -->
    <!-- <div class="all_game">
      <van-cell :title="$t('Merchant')" is-link to="/Merchants" :value="$t('all')" />
    </div> -->
    <div class="shop_wrap">
      <div class="content_wrap">
        <!-- <van-cell :title="$t('Merchant')" is-link :value="$t('all')" /> -->
        <!-- <van-cell :title="$t('Merchant')" is-link to="/Merchants" /> -->
        <div class="merchants_wrap">
          <div class="merchant_item" v-for="(item, index) in merchants" :key="index" @click="toGame(item)"
            :style="{ backgroundImage: `url(${require('@tt/assets/home/games/item_bg.png')})` }">
            <img :src="item.picture" alt="" />
            <div class="title">{{ item.title }}</div>
            <div class="subtitle">{{ item.subtitle }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 应用下载 -->
    <!-- <div class="download" v-if="$isDownloadFn">
      <div class="download_content" @click="downloadEvent()">
        <div>APP Download</div>
        <div><img src="../../assets/Img/myImg/down.png" alt="" /></div>
      </div>
    </div> -->
    <!-- 名人堂-轮播 -->
    <div class="hall">
      <div class="title">
        {{ $t("Home.HallofFame") }}
      </div>
      <HallMarquee class="marquee" :height="208">
        <template v-slot="{ item }">
          <div class="item">
            <div class="left">
              <Avatar size="40" :src="$img(
            `/uploads/system/userimg/userimg_${Math.round(
              Math.random() * 10
            )}.png?x`
          )
            " :defaultSrc="require('@tt/assets/defaultAvatar/A6405.png')" />
              <div class="info">
                <div class="nick_name">
                  {{ item.name }}
                </div>
                <div class="text">
                  {{ $t("successWithdrawof") }}
                </div>
              </div>
            </div>
            <div class="right">+{{ $money(item.money, false, { moneyType: item.moneyType }) }}</div>
          </div>
        </template>
      </HallMarquee>
    </div>
    <van-dialog v-model="dialogShow" :showConfirmButton="false" close-on-click-overlay>
      <img style="width: 100%" :src="homeImg" />
      <div class="close">
        <van-icon class="icon" name="close" @click="dialogShow = false" />
      </div>
    </van-dialog>
    <!-- <Popup v-model="ruleIsShow">
      <img :src="require('@tt/assets/home/rule.jpg')" width="100vw" alt="">
    </Popup> -->
  </div>
</template>

<script>
import Tabbar from "@/components/Tabbar";
import LoadingRE from "@/components/LoadingRE";
import VueClipboard from "vue-clipboard2";
import { Toast, Badge } from "vant";
import Cookies from "js-cookie";
import { GET_CONFIG_INFO, UNREAD_NUM } from "@tt/api";
import { Swipe, SwipeItem, Collapse, CollapseItem, Cell, Icon, Popup, ImagePreview, Dialog } from "vant";
import dataDetail from "@/utils/dataDetail";
import verConfig from "@/utils/verConfig";
import { $post } from "@/utils/request";
// import Banner from "@/components/banner.vue";
import { Banner,Avatar } from "@tt/components";
// import Avatar from "@/components/avatar";
import HallMarquee from "@/components/hallMarquee.vue";
import { HOME_IMG } from "@/api"
export default {
  components: {
    Popup,
    Tabbar,
    LoadingRE,
    VueClipboard,
    Toast,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Cell.name]: Cell,
    Banner,
    Avatar,
    HallMarquee,
    [Icon.name]: Icon,
    Badge,
    [Dialog.name]: Dialog,
    [Dialog.Component.name]: Dialog.Component
  },
  data() {
    return {
      dialogShow: false,
      homeImg: '',
      verConfig,
      islocalImg: true,
      userInfo: {
        money: 0,
        frozen_price: 0,
        complete_total_price: 0,
        complete_yesterday_price: 0,
        frozen_rate_price: 0,
      },
      country: Cookies.get("language"),
      bannerArr: [],
      noticeArr: [],
      inimenuArr: [
        {
          title: this.$t("newAdd.missionHall"),
          img: require("../../assets/icon/1.png"),
          activeImg: require("../../assets/icon/1-active.png"),
        },
        {
          title: this.$t("newAdd.helpCenter"),
          img: require("../../assets/icon/3.png"),
          activeImg: require("../../assets/icon/3-active.png"),
        },
        {
          title: this.$t("newAdd.inviteFriends"),
          img: require("../../assets/icon/7.png"),
          activeImg: require("../../assets/icon/7-active.png"),
        },
      ],
      menuArr: [],
      noticeArrss: [],
      menuVal: 0,
      allowed: 0,
      gradeArr: [
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
        {
          title: "",
          rate: "",
        },
      ],
      videoArr: [
        {
          title: "video1",
        },
        {
          title: "video2",
        },
        {
          title: "video3",
        },
        {
          title: "video4",
        },
      ],
      helpData: [],
      activeNames: 0,
      service_url: "",
      loadingNum: 0,
      noticeShowIndex: 3,
      unread: 0,
      ruleIsShow: false
      // merchants: [],
    };
  },
  computed: {
    merchants() {
      return this.$store.getters.AllGameList;
    }
  },
  methods: {
    async getHome() {
      this.loadingNum++;
      try {
        const { data } = await HOME_IMG();
        if (data && data.length > 0) {
          this.homeImg = data[0].picture;
          this.dialogShow = true
          this.loadingNum--;
        }else{
          this.homeImg = ''
          this.dialogShow = false
        }
      } catch (error) {
        this.loadingNum--;
        this.homeImg = ''
        this.dialogShow = false
      }
    },
    showRule() {
      // this.ruleIsShow = true;
      ImagePreview([require('@/assets/home/rule.png')]);
    },
    async getUnread() {
      this.loadingNum++;
      try {
        const res = await UNREAD_NUM();
        res && (this.unread = res);
        this.loadingNum--;
      } catch (error) {
        this.loadingNum--;
      }
    },
    // add:点击充值的按钮
    RechargeEvent() {
      this.$router.push("/new-recharge");
    },
    // add 分享
    goshare() {
      this.$router.push("/inviteFriends");
    },
    // add:点击提现按钮
    WithdrawEvent() {
      this.$router.push({ name: "newWithdraw" });
    },
    // add:去下载
    downloadEvent() {
      if (localStorage.getItem("android")) {
        location.href = localStorage.getItem("android");
      } else {
        location.href = "http://www.baidu.com";
      }
    },
    // add/随机名字
    randName() {
      let name =
        String(dataDetail.rand(72, 99)) +
        dataDetail.rand(3, 9) +
        "****" +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10) +
        parseInt(Math.random() * 10);
      return name;
    },
    // add/随机
    randMoney() {
      let probability = dataDetail.rand(0, 100);
      if (probability <= 30) {
        return dataDetail.rand(100, 10000);
      } else {
        return dataDetail.rand(10000, 300000);
      }
    },
    // add:lunbo
    noticeIni() {
      for (let i = 0; i < 12; i++) {
        this.noticeArrss.push({
          name: this.randName(),
          money: this.randMoney(),
        });
      }
      // console.log(this.noticeArrss);
    },
    // add:lunbo
    noticeDataUpdate() {
      setInterval(() => {
        this.noticeArrss.splice(this.noticeShowIndex, 1, {
          name: this.randName(),
          money: this.randMoney(),
        });
        if (this.noticeShowIndex >= 4) {
          this.noticeShowIndex = 1;
        } else {
          this.noticeShowIndex++;
        }
      }, 3000);
    },
    toInvite() {
      this.$router.push({ name: "InviteFriends" });
    },
    toRecharge() {
      // Dialog.alert({
      //   title: this.$t('My.MemberInformation.Recharge'),
      //   message: this.$t('hint.lxkf'),
      //   showCancelButton: true,
      //   confirmButtonText: this.$t('Login.goLoginText'),
      //   cancelButtonText: this.$t('newAdd.Cancel')
      // }).then(() => {
      //   this.$api.toService();
      // }).catch(() => {
      // });
      // return
      this.$router.push({ name: "NewRecharge" });
    },
    toService() {
      this.$api.toService();
    },
    toWithdraw() {
      this.$router.push({ name: "newWithdraw" });
    },
    copySuccess() {
      // Toast('Copy success!')
      Toast(this.$t("newAdd.copySuccess"));
    },
    copyError() {
      // Toast('Copy the failure!')
      Toast(this.$t("newAdd.copyError"));
    },
    menuChange(val) {
      this.menuVal = val;
    },
    handleChange(val) {
    },
    // 获取等级数组
    getLevels() {
      this.$api.getLevel((res) => {
        this.loadingNum--;
        this.gradeArr = res;
      });
    },
    // 获取用户信息
    getUserInfo() {
      this.loadingNum++;
      if (!this.userInfo.level) {
        this.$api.getUserInfo(
          (res) => {
            if (res.identity.indexOf("agent") == -1) {
              this.inimenuArr.splice(2, 1);
            }
            this.userInfo = res;
            this.loadingNum--;
            // Toast.clear()
          },
          (err) => {
            // Toast.clear()
            this.loadingNum--;
          }
        );
      }
    },
    toUnlock() {
      this.$router.push({ name: "levelUpgrade" });
    },
    getNotice() {
      this.$api.getNotice((res) => {
        this.noticeArr.push(res.scroll_notice);
        this.service_url = res.service_url;
        localStorage.setItem("android", res.appurl_android);
        localStorage.setItem("service_url", res.service_url);
      });
    },
    getBanner() {
      this.loadingNum++;
      this.$api.getBanner(
        (res) => {
          this.loadingNum--;
          if (res.length) {
            this.bannerArr = res;
            this.islocalImg = false;
          }
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    getConfigShare() {
      this.loadingNum++;
      GET_CONFIG_INFO({
        act: "share",
      })
        .then((r) => {
          this.loadingNum--;
          if (r.data.ret === 1) {
            this.inviteLink = r.data.data.text;
            this.$cookie.set("public-share-item", JSON.stringify(r.data.data));
          } else {
            this.inviteLink = "";
            this.$cookie.set("public-share-item", {});
          }
        })
        .catch((e) => {
          console.error(e);
          this.loadingNum--;
        });
    }, // 获取分享地址
    getMenuArr() {
      this.loadingNum++;
      this.$api.getHomeConfig(
        (res) => {
          this.loadingNum--;
          this.menuArr = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    getHelpArr() {
      this.loadingNum++;
      this.$api.getHomeHelp(
        (res) => {
          // console.log(res);
          this.loadingNum--;
          for (let i = 0; i < res.lists.length; i++) {
            let res_item = res.lists[i];
            res_item.content = res_item.content.replace(/<[^>]*>/gi, "");
          }
          this.helpData = res.lists;
        },
        (err) => {
          this.loadingNum--;
        }
      );
    },
    // 获取商家(玩法)数据
    // async getMerchants() {
    //   try {
    //     const res = await $post("/lottery/setting");
    //     const { ret, msg, data } = res.data;
    //     this.merchants = data;
    //     this.$cookie.set("merchants", JSON.stringify(this.merchants));
    //   } catch (error) {}
    // },
    // 进入玩法
    toGame(item) {
      this.$router.push(`/Game?type=${item.type}`);
    },
  },
  created() {
    Toast.loading({
      forbidClick: true,
      duration: 0,
    });
    if (this.$cookie.get("userInfo")) {
      this.getUserInfo();
    }
    // this.getLevels();
    this.getNotice();
    this.getBanner();
    this.getConfigShare();
    this.getMenuArr();
    this.getHelpArr();
    this.getUnread();
    this.getHome()
    // this.getMerchants();
  },
  mounted() {
    if (!this.$cookie.get("token")) {
      this.inimenuArr.splice(2, 1);
    }
    this.noticeIni();
    // this.noticeDataUpdate();
  },
  destroyed() {
  },
  watch: {
    loadingNum: (newVal) => {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      if (!newVal) {
        setTimeout(() => {
          Toast.clear();
        }, 1000);
      }
      setTimeout(()=>{
        Toast.clear();
      },10000)
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../assets/iconfonts/iconfont.css");
// @Color: #e23d6d;
@Color: var(--theme);
@comColor: var(--theme);
@wrapShadow: rgba(0, 0, 0, 0.12) 0px 4px 16px;
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;
// @comBG: linear-gradient(#093958, #022c47);
@comBG: var(--bg1);

// 弹性布局
.flex(@JC: center; @FW: nowrap; @AI: center; @FD: row) {
  display: flex;
  flex-wrap: @FW;
  flex-direction: @FD;
  justify-content: @JC;
  align-items: @AI;
}

// 文字超出省略号
.text-overflow(@num: 1) {
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: @num;
}

.spancolor {
  color: @Color;
}

.wrap {
  // background: #f5f5f5;
  min-height: 100vh;
  overflow: hidden;

  .swiper {
    // background: url(../../assets/Img/myImg/bg_01.6e175c41.png) no-repeat top;
    background-size: 100% 100%;
    // padding: 5vw 0 9.333vw 4vw;
    // padding: 5vw 0 2vw 4vw;
    padding: @wrapPadding;
    // background: @comColor;
    background: var(--navBg);
    // height: 200px;
    color: var(--textColor);
    // color: @comColor;

    .title_userinfo {
      // .flex(space-between);
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .logo {
        height: calc(40rem / 16);
        display: block;
        // margin: 0 auto;
      }

      .right {
        display: flex;
        align-items: center;

        .icon_rule {
          // position: absolute;
          // left: @wrapPadding;
          // top: 0;
          // line-height: calc(60rem / 16);
          color: var(--gray2);
          margin-right: calc(15rem / 16);

          >i {
            font-size: calc(26rem / 16);
          }
        }

        .share_content {
          // padding-right: 40px;
          // color: #8ba6c1;
          // position: absolute;
          // right: @wrapPadding;
          // top: 0;
          // line-height: calc(60rem / 16);
          color: var(--gray2);

          .btn_server_icon {
            font-size: calc(34rem / 16);
          }

          img {
            width: 7vw;
            height: 7vw;
          }
        }
      }



      .info {
        .flex(start);

        .contentLeft_Vip {
          background: rgba(255, 255, 255, 0.2);
          // background: rgba(0, 0, 0, 0.2);
          text-align: center;
          border-radius: 20px;
        }
      }

      .right {
        .user {
          margin-left: 2vw;

          >div {
            margin-top: calc(10rem / 16);

            &:nth-child(1) {
              margin-top: 0;
            }

            .label {
              color: var(--gray1);
            }

            .value {
              color: var(--textColor);
            }
          }
        }
      }
    }
  }

  .notice {
    margin-top: calc(5rem / 16);
    display: flex;
    align-items: center;
    padding: 3vw;

    >.icon {
      margin-right: 8px;
      // color: @Color;
      color: var(--textColor);
      font-size: calc(15rem / 16);
      line-height: 1;

      >img {
        width: calc(15rem / 16);
      }
    }

    >.text {
      display: flex;
      font-size: 14px;
      margin-top: -2px;
      line-height: 1;
      flex-grow: 1;
      white-space: nowrap;
      overflow: hidden;
      // color: @Color;
      color: #00bbfe;

      >marquee {
        >p {
          white-space: nowrap;

          >span {
            margin-right: 30px;
          }
        }
      }
    }
  }

  .banner_wrap {
    margin: @wrapPadding;
    background: #f5f5f5;
    border-radius: 10px;
    overflow: hidden;
    // box-shadow: @wrapShadow;
  }

  .title_balance {
    .user_content {
      width: 85%;
      margin: 0 auto;
      margin-top: 4vw;
      padding: 3vw 4vw;
      background-color: rgba(255, 255, 255, 0.2);
      // box-shadow: 0 0 2.667vw rgba(0, 0, 0, 0.2);
      border-radius: 1.333vw;

      .balance_wrap {
        .balance_case {
          .case_bottom {
            font-size: 12px;
          }

          text-align: center;
          .flex(space-between);
        }

        padding: 2vw 3vw;

        .balance_text {
          margin-bottom: 5vw;
        }

        .hr {
          width: 118%;
          border-top: 1px solid #de3d6d;
          border-bottom: 1px solid #ea84a3;
          margin: 5vw 0;
          margin-left: -7vw;
        }

        .contentCase_btns {
          .flex(space-around);

          .contentCase_btn {
            padding: 1.867vw 2vw;
            font-size: 3.733vw;
            line-height: normal;
            // color: #004986;
            color: var(--textColor);
            text-align: center;
            border-radius: 7.467vw;
            // background-color: #fff;
            background-color: @comColor;
            margin-left: 2vw;
          }
        }

        .moey_num {
          color: @Color;
          font-size: 6.4vw;
          font-weight: 400;
        }
      }
    }
  }

  .download {
    background-color: #f5f4f7;
    padding: 5vw 0;

    .download_content {
      .flex(space-between);
      width: 85vw;
      margin: 0 auto;
      background-color: #fff;
      // box-shadow: 0 0 2.667vw rgba(0, 0, 0, 0.05);
      border-radius: 2.667vw;
      padding: 3vw;

      img {
        width: 30px;
      }
    }
  }

  // .people {
  //   .people_title {
  //     color: @Color;
  //     width: 100%;
  //     height: 6.333vw;
  //     padding: 4vw 4vw;
  //     font-weight: 700;
  //     position: relative;
  //     text-indent: 4vw;
  //     line-height: 7vw;
  //   }

  //   .people_title::before {
  //     display: block;
  //     content: "";
  //     background-color: @Color;
  //     width: 2vw;
  //     height: 6vw;
  //     position: absolute;
  //     border-radius: 2vw;
  //   }

  //   .marquee {
  //     height: 35vw;
  //   }

  //   .halloffame {
  //     padding: 0 5vw;
  //     line-height: 3em;
  //     font-size: 13px;
  //   }
  // }
  .hall {
    margin: @wrapPadding;
    padding: @wrapPadding;
    background: #fafdff;
    border-radius: @comRadius;

    .title {
      color: #ff7f23;
      margin-bottom: calc(5rem / 16);
    }

    .marquee {

      // height: calc(208rem / 16);
      .item {
        // margin: calc(10rem / 16) calc(4rem / 16) 0;
        margin-top: @wrapPadding;
        // padding: calc(7rem / 16) calc(5rem / 16);
        padding: calc(6rem / 16) calc(8rem / 16);
        padding-right: calc(14rem / 16);
        display: flex;
        justify-content: space-between;
        align-items: center;
        // border-radius: calc(15rem / 16);
        border-radius: 99px;
        background-color: #f0f6fa;

        .left {
          display: flex;
          font-size: calc(14rem / 16);

          .info {
            margin-left: calc(4rem / 16);
            padding: 1px 0;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .nick_name {
              color: #333;
            }

            .text {
              letter-spacing: 0;
              color: #333;
              margin-top: calc(8rem / 16);
            }
          }
        }

        .right {
          color: #ff7f23;
          font-size: calc(14rem / 16);
          letter-spacing: 0;
          // font-family: PingFangSC-Medium;
        }
      }
    }
  }

  .btns_wrap {
    margin: @wrapPadding;
    display: flex;
    // justify-content: space-between;
    // @itemGap: calc(10rem / 16);
    @itemGap: @wrapPadding;
    // box-shadow: @wrapShadow;

    .btn_item {
      padding: calc(6rem / 16) 0;
      // background: #002c47;
      // background: linear-gradient(#093a58, #002c47);
      background: #f5f5f5;
      // color: @comColor;
      color: var(--gray2);
      font-size: calc(12rem / 16);
      text-align: center;
      width: calc((100vw - @wrapPadding * 2 - @itemGap * 3) / 3);
      margin-left: @itemGap;
      border-radius: 6px;
      // box-shadow: @wrapShadow;

      &:nth-of-type(1) {
        margin-left: 0;
      }

      >i {
        font-size: calc(32rem / 16);
        color: #00bbfe;
      }

      img {
        height: calc(32rem / 16);
        color: #00bbfe;
      }

      .text {
        margin-top: calc(10rem / 16);
      }

      /deep/ .van-badge--fixed {
        top: calc(6rem / 16);
        right: calc(5rem / 16);
      }
    }
  }

  .all_game {
    margin: @wrapPadding;
    border-radius: @comRadius;
    overflow: hidden;

    /deep/ .van-cell {
      background: #063451;

      .van-cell__title {
        color: #00bbfe;
      }

      .van-cell__value {
        color: #8ba6c1;
      }

      .van-cell__right-icon {
        color: #8ba6c1;
      }
    }
  }

  .shop_wrap {
    // padding: 5px;
    margin: @wrapPadding;
    // background-color: #f5f5f5;
    // background: #fff;
    // padding-bottom: @wrapPadding;
    // box-shadow: @wrapShadow;
    @radius: @comRadius;
    border-radius: @radius;

    .content_wrap {
      // background-color: #fff;
      border-radius: @radius;
      overflow: hidden;
      // padding: calc(5rem / 16);
    }

    .merchants_wrap {
      display: flex;
      flex-wrap: wrap;

      .merchant_item {
        // margin: 5px;
        // margin-top: 0;
        margin-right: @wrapPadding;
        margin-top: @wrapPadding;
        border-radius: 8px;
        padding: 10px 0;
        // background-color: #ebecec;
        background: @comBG;
        width: calc((100% - @wrapPadding) / 2);
        text-align: center;
        // background-image: url("../../assets/home/merchant_item_bg.png");
        background-size: 100% 100%;

        >img {
          height: calc(80rem / 16);
          max-width: 100%;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        &:nth-child(1),
        &:nth-child(2) {
          margin-top: 0;
        }

        .title {
          font-size: 15px;
          color: var(--textColor);
          margin-top: calc(-2rem / 16);
        }

        .subtitle {
          font-size: 12px;
          color: var(--gray2);
          margin-top: calc(4rem / 16);
        }
      }
    }
  }
}
.close{
  position: absolute;
  top: 5px;
  right: 5px;
  .icon{
    font-size: 20px;
    color: #000;
  }
}
</style>
